import PhotoSwipeLightbox from '../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from '../../../node_modules/photoswipe/dist/photoswipe.esm.js';

const lightbox = new PhotoSwipeLightbox({
	gallery: '#gallery',
	children: 'a',
	pswpModule: PhotoSwipe,
	zoom: false,
	bgOpacity: 1
});

lightbox.on('uiRegister', function() {
	
	lightbox.pswp.ui.registerElement({
		name: 'custom-caption',
		order: 9,
		isButton: false,
		appendTo: 'root',
		html: 'Caption text',
		onInit: (el, pswp) => {
			
			lightbox.pswp.on('change', () => {
				
				const currSlideElement = lightbox.pswp.currSlide.data.element;
				let captionHTML = '';
				
				if (currSlideElement) {
					captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
				}
				
				el.innerHTML = captionHTML ? '<span>' + captionHTML + '</span>' : '';
				
			});
			
		}
	});
	
});

lightbox.init();